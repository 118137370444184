/* eslint-disable indent */
import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { PrismicRichText } from '@prismicio/react'
import Text from '../common/Text'
import Space from '../common/Space'
import Button from '../common/Button'
import { theme } from '../../styles'
import VideoIframe from '../common/VideoIframe'
import getImage from '../../utils/getImage'
import violetCheckMarkIcon from '../../../static/icons/check-icons/violet-check-icon.svg'
import LottieAnimation from '../LottieAnimation'
import ModalAnimated from '../common/modal/ModalAnimated'

import { useUserLang } from '../../context/UserLangContext'
import generateAriaLabelTranslations from '../../data/generateAriaLabelTranslations'

const BannerContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;

  &:after {
    ${p =>
      p.backgroundColor &&
      css`
        position: absolute;
        content: '';
        top: 0;
        z-index: -1;
        background-color: ${p.backgroundColor ? theme.color[p.backgroundColor] : theme.color.white};
        width: 500vw;
        margin-left: -300%;
        height: 100%;
      `}
  }
`

const BannerWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-gap: 48px;
  justify-content: space-between;
  justify-items: center;
  padding: 80px 0px;
  padding-top: ${p => (p.hasTopPadding ? '64px' : '0px')};
  padding-bottom: ${p => (p.hasBottomPadding ? '64px' : '0px')};

  @media (max-width: 1500px) {
    padding: 32px 0px;
    padding-top: ${p => (p.hasTopPadding ? '32px' : '0px')};
    padding-bottom: ${p => (p.hasBottomPadding ? '48px' : '0px')};
  }

  @media (max-width: 1300px) {
    padding: 24px 0px;
    padding-top: ${p => (p.hasTopPadding ? '24px' : '0px')};
    padding-bottom: ${p => (p.hasBottomPadding ? '24px' : '0px')};
  }

  @media (max-width: 1050px) {
    grid-template-columns: 1fr;
    grid-gap: 32px;
    padding: 32px 0px 20px 0px;
    padding-top: ${p => (p.hasTopPadding ? '32px' : '0px')};
    padding-bottom: ${p => (p.hasBottomPadding ? '20px' : '0px')};
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  width: 100%;

  @media (max-width: 1050px) {
    min-width: unset;
    align-self: center;
    justify-content: flex-start;
    align-items: center;

    * {
      margin: 0 auto;
      text-align: center !important;
    }
  }

  @media (max-width: 480px) {
    align-items: flex-start !important;

    * {
      margin: 0;
      text-align: left !important;
    }
  }

  @media (max-width: 800px) {
    h1 {
      font-size: 2.33rem !important;
    }
    h2 {
      font-size: ${theme.fonts.size.heading2}!important;
    }
  }
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1500px) {
    justify-content: flex-start;
    max-width: 600px;
  }

  @media (max-width: 1050px) {
    max-width: 80vw;
    margin: 0 auto;
  }

  @media (max-width: 800px) {
    display: ${p => (p.showImgMob ? 'block' : 'none')};
  }
`

const TitleText = styled(Text)`
  @media (max-width: 800px) {
    h1 {
      width: calc(100% + 12px);
      margin-left: -6px;
    }
  }
`

const BannerText = styled(Text)`
  li {
    font-size: 18px !important;
  }
  @media (max-width: 800px) {
    p,
    li {
      font-size: 16px !important;
    }
    p,
    p strong {
      font-size: ${theme.fonts.size.largeBody}!important;
    }
  }
`

const CheckMarkListWrapper = styled(Text)`
  ul {
    margin-left: 20px;
  }
  li {
    padding-inline-start: 12px;
    list-style-type: none;
    list-style-image: url(${violetCheckMarkIcon});

    @media (max-width: 1050px) {
      text-align: left;
    }

    @media (max-width: 800px) {
      text-align: left !important;
      margin-bottom: 16px;
    }
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;

  .button.primary,
  .button.secondary {
    min-width: 200px;

    @media (max-width: 1050px) {
      margin: 0px 8px;
    }

    @media (max-width: 800px) {
      margin: 0 auto;
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
    width: 100%;
  }

  @media (max-width: 480px) {
    * {
      text-align: center !important;
    }
  }
`

const Image = styled.img`
  object-fit: cover;
  width: 100%;

  @media (max-width: 1050px) {
    margin: 0 auto 20px auto;
  }

  @media (max-width: 800px) {
    display: ${p => (p.showImgMob ? 'block' : 'none')};
  }
`

const LottieWrapper = styled.div`
  object-fit: cover;
  width: 100%;

  @media (max-width: 1050px) {
    margin: 0 auto 20px auto;
  }

  @media (max-width: 800px) {
    display: ${p => (p.showImgMob ? 'block' : 'none')};
  }
`

const BannerTitle = ({ slice: { primary } }) => {
  const title = primary.title.richText?.[0]?.text && <PrismicRichText field={primary.title.richText} />
  const text = primary.text.richText?.[0]?.text && <PrismicRichText field={primary.text.richText} />
  const smallCapsText = primary.small_caps_text.richText?.[0]?.text && (
    <PrismicRichText field={primary.small_caps_text.richText} />
  )
  const largeCapsText = primary.large_caps_text.richText?.[0]?.text && (
    <PrismicRichText field={primary.large_caps_text.richText} />
  )
  const checkmarkList = primary.checkmark_list.richText?.[0]?.text && (
    <PrismicRichText field={primary.checkmark_list.richText} />
  )
  const imageSrc = primary?.image.url
  const hasTopPadding = primary?.has_top_padding
  const hasBottomPadding = primary?.has_bottom_padding
  const backgroundColor = primary?.background_color
  const video = primary?.modal_video.url
  const primaryButtonText = primary?.primary_button_text.text
  const primaryButtonLink = primary?.primary_button_link.url || primary?.primary_button_link.uid
  const secondaryButtonText = primary?.secondary_button_text.text
  const secondaryButtonLink = primary?.secondary_button_link.url || primary?.secondary_button_link.uid
  const anchorId = primary.anchor_id
  const showImgMob = primary.show_img_mobile
  const rawLottie = primary.animation && primary.animation.text
  const lottieAnim = rawLottie ? JSON.parse(primary.animation.text) : null
  const lottieAriaLabel = primary?.animation_aria_label

  const [open, setOpen] = useState(false)

  const { userLang } = useUserLang()
  const languageData = generateAriaLabelTranslations({ userLang })

  return (
    <BannerContainer backgroundColor={backgroundColor} id={anchorId || null}>
      <BannerWrapper
        hasTopPadding={hasTopPadding === null ? true : hasTopPadding}
        hasBottomPadding={hasBottomPadding === null ? true : hasBottomPadding}
      >
        <InfoWrapper>
          {smallCapsText && (
            <Text font="smallCaps" richText color="purple500">
              {smallCapsText}
            </Text>
          )}
          {largeCapsText && (
            <Text font="largeCaps" richText color="purple500">
              {largeCapsText}
            </Text>
          )}
          {title && (
            <TitleText font="heading1" style={{ marginBottom: '1.45rem' }} richText>
              {title}
            </TitleText>
          )}
          {text && (
            <BannerText font="body" richText>
              {text}
            </BannerText>
          )}
          {checkmarkList && <CheckMarkListWrapper richText>{checkmarkList}</CheckMarkListWrapper>}
          {primaryButtonText || secondaryButtonText ? (
            <>
              <Space height={24} tabletHeight={16} />
              <ButtonWrapper>
                {primaryButtonText && <Button text={primaryButtonText} to={primaryButtonLink} className="primary" />}
                <Space height={16} width={16} tabletHeight={12} mobileWidth={0} mobileHeight={0} />
                {secondaryButtonText && (
                  <Button
                    buttonType="secondary"
                    text={secondaryButtonText}
                    to={secondaryButtonLink}
                    className="secondary"
                  />
                )}
                <Space height={8} mobileHeight={0} />
              </ButtonWrapper>
            </>
          ) : (
            <Space tabletHeight={32} mobileHeight={0} />
          )}
        </InfoWrapper>
        {(video || imageSrc || lottieAnim) && (
          <ImageWrapper showImgMob={showImgMob}>
            {video ? (
              <a
                tabIndex="0"
                role="button"
                aria-label={languageData?.playVideo}
                onKeyDown={e => {
                  if (e.key === 'Enter') setOpen(true)
                }}
                onClick={() => video && setOpen(true)}
              >
                {getImage(primary.image, Image, { showImgMob })}
              </a>
            ) : lottieAnim && typeof lottieAnim === 'object' ? (
              <LottieWrapper showImgMob={showImgMob}>
                <LottieAnimation lotti={lottieAnim} lottieAriaLabel={lottieAriaLabel} />
              </LottieWrapper>
            ) : (
              getImage(primary.image, Image, { showImgMob, loading: 'eager' })
            )}
          </ImageWrapper>
        )}
        {video && (
          <ModalAnimated
            active={(video && open) === true}
            onClose={() => video && setOpen(false)}
            ariaLabel={languageData?.videoModal}
          >
            <VideoIframe isModal allowFullScreen videoSrc={video} autoPlay={false} />
          </ModalAnimated>
        )}
      </BannerWrapper>
    </BannerContainer>
  )
}

export default BannerTitle

export const query = graphql`
  fragment BannerTitle on PrismicPageDataBodyBannerTitle {
    ...SliceBase
    id
    primary {
      anchor_id
      has_top_padding
      has_bottom_padding
      show_img_mobile
      background_color
      modal_video {
        link_type
        ...LinkContent
      }
      secondary_button_text {
        ...TextContent
      }
      secondary_button_link {
        ...LinkContent
      }
      primary_button_text {
        ...TextContent
      }
      primary_button_link {
        ...LinkContent
      }
      small_caps_text {
        ...TextContent
      }
      large_caps_text {
        ...TextContent
      }
      title {
        ...TextContent
      }
      text {
        ...TextContent
      }
      checkmark_list {
        ...TextContent
      }
      animation {
        ...TextContent
      }
      animation_aria_label
      image {
        alt
        url
        gatsbyImageData(width: 1080)
      }
    }
  }
`
